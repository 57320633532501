.App {
  text-align: center;
}
/*.MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1480iag-MuiInputBase-root-MuiInput-root {*/
/*  margin-top: 0px;*/
/*}*/
.center{
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.logo {
  aspect-ratio: 2;
  width: 10em;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: 0.2s;
}

.logo:hover{
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.26));
}

.verticallyCenter{
  display: flex;
  align-items: center;
}

.rocketIcon {
  margin-bottom: 4px;
  margin-left: 7px;
}

.signin {
  position: fixed;
  right: 1.5ch;
  top: 1.5ch;
  display: flex;
}

.username{
  margin-right: 25px;
  font-weight: 700;
}

.profilePic{
  width: 3em;
  border-radius: 100%;
  margin-right: 30px;
}

.tej{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 0;
}

.card{
  display: block;
  padding: 20px;
  margin-top: 10px;
  width: min(70vw, 10cm);
}

.previewBox{
  /*position: absolute;*/
  /* top: 0; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  margin-top: 10px;
  overflow: auto;
  height: 50vh;
}


/*.inputContainer {*/
/*  display: flex;*/
/*}*/

/*.uploadContainer {*/
/*  margin-right: 8px;*/
/*  height: 11ch;*/
/*  margin-top: 8px;*/
/*  width: 25ch;*/
/*}*/

.uploadButton{
  width: inherit;
  height: 50%;
  margin-top: 0.25cm;
  margin-right: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (min-width: 800px) {
  .variableWidth{
    width: 75%;
  }


  .inputContainer {
    display: flex;
  }

  .uploadContainer {
    margin-right: 8px;
    height: 11ch;
    margin-top: 8px;
    width: 25ch;
  }

}

@media screen and (max-width: 799px) {
  .variableWidth{
    width: 90%;
  }

  .inputContainer {
    display: block;
  }

  .fullWidthTextField{
    width: 100% !important;
    margin: 0px !important;
  }

  .arrow{
    text-align: center;
    transform: rotateZ(90deg);
  }

  .uploadContainer {
    /*margin-right: 8px;*/
    /*height: 11ch;*/
    /*margin-top: 8px;*/
    /*width: 25ch;*/
  }

}


